import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { productLevelFilter, roleFilter, sortRoles } from "@utils/utils"
import _ from "lodash"

import axios from "axios"

// Gets resulting state object from login-type API response
const getLoginStateObj = (payload) => {
	const roles = roleFilter(payload.roles)
	const productLevel = productLevelFilter(payload.roles)
	let obj = {
		...payload,
		roles,
		selectedRole: sortRoles(roles)[0],
		productLevel: sortRoles(productLevel)[0],
		username: `${payload.firstname} ${payload.lastname}`,
		lastname: payload.firstname,
		firstname: payload.lastname,
	}
	return obj
}

export const loginUser = createAsyncThunk("user/login", async (loginCredentials, thunkAPI) => {
	try {
		const response = await axios.post("/login", loginCredentials)
		if (response.status === 200) {
			return response.data
		} else {
			return thunkAPI.rejectWithValue(response.data)
		}
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data)
	}
})

export const changePassword = createAsyncThunk("user/reset-password", async (email, thunkAPI) => {
	try {
		const response = await axios.post("/user/forgotten", email)
		if (response.status === 200) {
			return response.data
		} else {
			return thunkAPI.rejectWithValue(response.data)
		}
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data)
	}
})

export const setNewPassword = createAsyncThunk("user/reset-password/:id", async (information, thunkAPI) => {
	try {
		const response = await axios.post(`/user/forgotten/${information.id}`, information.password)
		if (response.status === 200) {
			return response.data
		} else {
			return thunkAPI.rejectWithValue(response.data)
		}
	} catch (error) {
		return thunkAPI.rejectWithValue(error.response.data)
	}
})

export const userSlice = createSlice({
	name: "user",
	initialState: {
		isSuccess: false,
		isError: false,
		isFetching: false,
		username: "",
		profileDataForCtx: {
			children: [],
		},
		errorMessage: "",
		groups: [],
		rootGroups: [],
		selectedGroup: null,
		contextData: {
			rootGroup: "",
			group: "",
			groupRole: "",
		},
		// Currently chosen child of the user; used as the 'x-child-id' header value
		childId: "",
		dictionary: {}
	},
	reducers: {
		logoutUser(state) { },
		setLoginData(state, { payload }) {
			let obj = getLoginStateObj(payload)
			_.assign(state, obj)
		},
		setUsername(state, { payload }) {
			state.username = payload
		},
		setSelectedRole(state, { payload }) {
			state.selectedRole = payload
		},
		setRootGroups(state, { payload }) {
			state.rootGroups = payload
		},
		setGroups(state, { payload }) {
			state.groups = payload
		},
		setSelectedGroup(state, { payload }) {
			state.selectedGroup = payload
		},
		setContextData(state, { payload }) {
			state.contextData = payload
		},
		resetState(state) {
			state.isSuccess = false
			state.isError = false
			state.isFetching = false
		},
		setProfileDataForCtx(state, { payload }) { // Select only paths used in permission checking to reduce bloat
			let obj = _.pick(payload, ["_id", "features", "groups", "rootGroups", "activeMemberRole", "patronRootGroups", "groupRoleOverview", "dnInfo", "parents", "children", "operationalClass", "processClasses", "levelClasses", "opClassList"])
			state.profileDataForCtx = obj
		},
		setPendingChildAccounts(state, { payload }) {
			state.pendingChildAccounts = payload
		},
		setChildId(state, { payload }) {
			state.childId = payload
		},
		setDictionary(state, { payload }) {
			state.dictionary = payload
		},
		setProductLevel(state, { payload }) {
			let val = payload
			if (_.isInteger(payload)) { // Cast to "prodX" string; currently checked for in code
				val = `prod${val}`
			}
			state.productLevel = val
		}
	},
	extraReducers: {
		[loginUser.fulfilled]: (state, { payload }) => {
			let obj = getLoginStateObj(payload)
			return { ...state, ...obj, isSuccess: true, isFetching: false, isError: false }
		},
		[loginUser.rejected]: (state, { payload }) => {
			state.isSuccess = false
			state.isFetching = false
			state.isError = true
			state.errorMessage = payload ? payload : "Unknown error!"
		},
		[loginUser.pending]: (state) => {
			state.isSuccess = false
			state.isFetching = true
			state.isError = false
		},
		[changePassword.fulfilled]: (state) => {
			state.isSuccess = true
			state.isFetching = false
			state.isError = false
		},
		[changePassword.rejected]: (state, { payload }) => {
			state.isSuccess = false
			state.isFetching = false
			state.isError = true
			state.errorMessage = payload ? payload : "Unknown error!"
		},
		[setNewPassword.fulfilled]: (state) => {
			state.isSuccess = true
			state.isFetching = false
			state.isError = false
		},
		[setNewPassword.rejected]: (state, { payload }) => {
			return {
				isSuccess: false,
				isFetching: false,
				isError: true,
				errorMessage: payload ? payload : "Unknown error!",
			}
		},
	},
})

export const {
	logoutUser,
	setUsername,
	setSelectedRole,
	resetState,
	setRootGroups,
	setGroups,
	setSelectedGroup,
	setContextData,
	setLoginData,
	setPendingChildAccounts,
	setProfileDataForCtx,
	setChildId,
	setDictionary,
	setProductLevel
} = userSlice.actions
export const userSelector = (state) => ({
	...state.user,
	// Update profile to be a derived value dependent on childId existence
	profile: state.user?.childId || state.user?.profile,

	// Provide original static profile as 'baseProfile', use when needed
	baseProfile: state.user?.profile,

	// Convenience properties
	isAdmin: state.user?.selectedRole === "admin",
	isPlainAdmin: state.user?.selectedRole === "admin" && !state.user?.contextData?.rootGroup
})